import { Button, Form, FormProps, Input, notification } from "antd";
import React, { Dispatch } from "react";
import { FieldType } from "../../types";
import {
  ApplianceThunkType,
  createAppliance,
} from "../../store/actions/applianceActions";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../store";

interface IProps {
  activeCategory: string;
}

export const CreateApplianceInput = ({ activeCategory }: IProps) => {
  const dispatch = useDispatch() as Dispatch<ApplianceThunkType | AnyAction>;
  const [form] = Form.useForm();
  const isEditingApplianceInProgress = useSelector(
    (state: AppStateType) => state.appliance.isEditingApplianceInProgress
  );
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (values.name) {
      dispatch(createAppliance({ ...values, category: activeCategory }));
    } else {
      notification.error({
        message: "Error",
        description: "Empty category name",
      });
    }

    form.setFieldValue("name", "");
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      style={{ display: "flex", flexDirection: "row" }}
    >
      <Form.Item<FieldType> name="name">
        <Input
          placeholder="Subcategory name"
          style={{ width: 200 }}
          size="middle"
        />
      </Form.Item>
      <Button
        style={{ marginLeft: 10 }}
        htmlType="submit"
        loading={isEditingApplianceInProgress}
        type="primary"
        size="middle"
        disabled={false}
      >
        Create
      </Button>
    </Form>
  );
};
