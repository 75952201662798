import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpecificPageValues,
  SiteThunkType,
  updSpecificPageValues,
} from "../../store/actions/siteActions";
import { AnyAction } from "redux";
import { AppStateType } from "../../store";
import {
  IAppliance,
  IBrandAppliance,
  ISiteImage,
  ISpecificPageValue,
} from "../../types";
import { Button, Form, Input, Popconfirm, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { mainUrl } from "../../helpers/constants";
import { deleteOneSiteImage } from "../../store/actions/imageActions";
const { Title } = Typography;

interface IProps {
  siteId: string;
  appliances: IAppliance[];
  applianceBrands: IBrandAppliance[];
  siteImages: ISiteImage[];
}

interface IVal {
  [param: string]: { [param: string]: ISpecificPageValue[] };
}

type FieldType = {
  [param: string]: string;
};

export const SpecificPageValues = ({
  siteId,
  appliances,
  applianceBrands,
  siteImages,
}: IProps) => {
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;
  const [values, setValues] = useState<IVal | null>(null);
  const [valuesToUpdate, setValuesToUpdate] = useState<{
    [param: string]: string;
  }>({});
  const specificPageValues = useSelector(
    (state: AppStateType) => state.site.specificPageValues
  );
  const isUpdSpecificPageValuesInProgress = useSelector(
    (state: AppStateType) => state.site.isUpdSpecificPageValuesInProgress
  );
  const isLoadingSpecificPageValuesInProgress = useSelector(
    (state: AppStateType) => state.site.isLoadingSpecificPageValuesInProgress
  );

  const isImageDeletingInProgress = useSelector(
    (state: AppStateType) => state.image.isImageDeletingInProgress
  );

  useEffect(() => {
    if (siteId) {
      dispatch(getSpecificPageValues(siteId));
    }
  }, [siteId]);

  useEffect(() => {
    let vals = {} as IVal;
    specificPageValues.forEach((spv) => {
      if (spv.promtForSpecificPage.page) {
        vals[spv.promtForSpecificPage.page] = {
          ...vals[spv.promtForSpecificPage.page],
          [spv.itemName]: [
            ...(vals[spv.promtForSpecificPage.page]?.[spv.itemName]
              ? vals[spv.promtForSpecificPage.page]?.[spv.itemName]
              : []),
            spv,
          ],
        };
      }
    });
    setValues(vals);
  }, [specificPageValues]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (values && form) {
      specificPageValues.forEach((spv) => {
        form.setFieldValue(
          `${spv.itemName}${spv.promtForSpecificPage.tag.fieldInDb}`,
          spv.itemValue
        );
      });
    }
  }, [specificPageValues, values, form]);

  useEffect(() => {
    console.log("valuesToUpdate", valuesToUpdate);
  }, [valuesToUpdate]);

  return (
    <div
      style={{
        borderLeft: "1px solid",
        paddingLeft: 20,
        width: 500,
        paddingRight: 20,
      }}
    >
      <Form
        style={{
          minWidth: 400,
          borderBottom: "1px solid",
          paddingBottom: 20,
        }}
        form={form}
        layout="vertical"
      >
        {values &&
          Object.keys(values).map((val) => (
            <div key={val}>
              {/* <Title style={{ marginBottom: 20, color: "red" }} level={3}>
              {val.toLocaleUpperCase()}
            </Title> */}
              {Object.keys(values[val]).map((item) => {
                const siteImage = siteImages.find(
                  (si) => si.site === siteId && si.category === item
                );
                return (
                  <div key={item}>
                    <Title style={{ marginBottom: 20, color: "red" }} level={3}>
                      {val.toLocaleUpperCase()} PAGE (
                      {appliances.find((a) => a._id === item)?.name ||
                        applianceBrands.find((apb) => apb.brand._id === item)
                          ?.brand.name}
                      )
                    </Title>
                    {siteImage && (
                      <div style={{ position: "relative" }}>
                        <img
                          width="200"
                          alt="company logo"
                          src={`${mainUrl}${siteImage?.url.replace(
                            "/data/uploads/",
                            ""
                          )}`}
                        />
                        <Popconfirm
                          title="Confirmation"
                          description={`Delete?`}
                          onConfirm={() => {
                            if (siteId) {
                              dispatch(
                                deleteOneSiteImage(siteImage._id, siteId)
                              );
                            }
                          }}
                        >
                          <Button
                            style={{ position: "absolute", left: 0 }}
                            onClick={() => null}
                            type="primary"
                            loading={isImageDeletingInProgress}
                          >
                            Delete
                          </Button>
                        </Popconfirm>
                      </div>
                    )}

                    {values[val][item].map((t) => (
                      <Form.Item<FieldType>
                        key={t.promtForSpecificPage.tag.fieldInDb}
                        label={
                          <Title style={{ marginTop: 0 }} level={4}>
                            {t.promtForSpecificPage.tag.fieldInDb}
                          </Title>
                        }
                        name={`${item}${t.promtForSpecificPage.tag.fieldInDb}`}
                      >
                        <TextArea
                          disabled={isLoadingSpecificPageValuesInProgress}
                          onChange={(val) =>
                            setValuesToUpdate({
                              ...valuesToUpdate,
                              [t._id]: val.target.value,
                            })
                          }
                          rows={2}
                          size="middle"
                          placeholder={`Enter ${t.promtForSpecificPage.tag.fieldInDb}`}
                        />
                      </Form.Item>
                    ))}

                    <Button
                      style={{ width: 150 }}
                      onClick={() => {
                        dispatch(updSpecificPageValues(siteId, valuesToUpdate));
                        setValuesToUpdate({});
                      }}
                      loading={isUpdSpecificPageValuesInProgress}
                      type="primary"
                      size="middle"
                      disabled={isLoadingSpecificPageValuesInProgress}
                    >
                      Save
                    </Button>
                  </div>
                );
              })}
            </div>
          ))}
      </Form>
    </div>
  );
};
