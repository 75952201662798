import { useDispatch, useSelector } from "react-redux";
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
  RocketOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import { Button, Modal, Popconfirm, Typography } from "antd";
import { AppStateType } from "../store";
import { SitesPreloader } from "../components/sites/SitesPreloader";

import { CreateNewSite } from "../components/sites/CreateNewSite";
import { Dispatch, useEffect, useState } from "react";
import {
  SiteThunkType,
  deleteSites,
  getSites,
  rebuildWithNewContent,
  setInProgressSiteId,
  updateManySites,
  updateSite,
} from "../store/actions/siteActions";
import { AnyAction } from "redux";
import { SitesList } from "../components/sites/SitesList";
import { getStates } from "../store/actions/locationActions";
import { getCategories } from "../store/actions/categoriesActions";
import { IOnInProgressId, IOnSitesUpdate } from "../types";
import { siteAPI, tagsApi } from "../api";
import { deleteAllSiteImages } from "../store/actions/imageActions";
// eslint-disable-next-line react-hooks/rules-of-hooks

export const Sites = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedSites, setCheckedSites] = useState<string[]>([]);
  const [checkedSitesWithError, setCheckedSitesWithError] = useState<string[]>(
    []
  );

  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;

  const { Title } = Typography;

  const isGettingSitesInProgress = useSelector(
    (state: AppStateType) => state.site.isGettingSitesInProgress
  );

  const sites = useSelector((state: AppStateType) => state.site.sites);
  const sortByField = useSelector(
    (state: AppStateType) => state.site.sortByField
  );
  const sortBy = useSelector((state: AppStateType) => state.site.sortBy);
  const page = useSelector((state: AppStateType) => state.site.page);
  const serchByName = useSelector(
    (state: AppStateType) => state.site.serchByName
  );
  const serchByState = useSelector(
    (state: AppStateType) => state.site.serchByState
  );
  const inProgressId = useSelector(
    (state: AppStateType) => state.site.inProgressId
  );
  const serchByCity = useSelector(
    (state: AppStateType) => state.site.serchByCity
  );
  const serchByZip = useSelector(
    (state: AppStateType) => state.site.serchByZip
  );
  const serchByDomain = useSelector(
    (state: AppStateType) => state.site.serchByDomain
  );
  const serchByCompany = useSelector(
    (state: AppStateType) => state.site.serchByCompany
  );
  const serchByStatus = useSelector(
    (state: AppStateType) => state.site.serchByStatus
  );
  const isDeletionInProgress = useSelector(
    (state: AppStateType) => state.site.isDeletionInProgress
  );
  const isUpdatingSiteInProgress = useSelector(
    (state: AppStateType) => state.site.isUpdatingSiteInProgress
  );
  const serchByCounty = useSelector(
    (state: AppStateType) => state.site.serchByCounty
  );
  const serchByError = useSelector(
    (state: AppStateType) => state.site.serchByError
  );
  const searchByInProgress = useSelector(
    (state: AppStateType) => state.site.searchByInProgress
  );

  const isImageDeletingInProgress = useSelector(
    (state: AppStateType) => state.image.isImageDeletingInProgress
  );

  const socket = useSelector((state: AppStateType) => state.auth.socket);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getStates());
  }, []);

  useEffect(() => {
    dispatch(getSites());
  }, [
    sortBy,
    sortByField,
    page,
    serchByName,
    serchByState,
    serchByCity,
    serchByZip,
    serchByDomain,
    serchByCompany,
    serchByStatus,
    serchByCounty,
    serchByError,
    searchByInProgress,
  ]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onSitesUpdate = ({ siteId }: IOnSitesUpdate) => {
    console.log("siteId", siteId);
    if (siteId) {
      dispatch(getSites());
    }
  };

  // const onInProgressId = ({ inProgressId }: IOnInProgressId) => {
  //   console.log("inProgressId", inProgressId);
  //   dispatch(setInProgressSiteId(inProgressId));
  // };

  useEffect(() => {
    socket?.on("sitesUpdate", onSitesUpdate);
    // socket?.on("inProgressId", onInProgressId);
  }, [socket]);

  useEffect(() => {
    const sd = [...checkedSites].filter((cs) =>
      sites.some((site) => site.error && cs === site._id)
    );

    setCheckedSitesWithError(sd);
  }, [checkedSites, sites]);

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: 20,
          paddingTop: 10,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Title level={3}>Sites</Title>
            {checkedSites.length > 0 && (
              <div style={{ marginTop: 19, marginLeft: 10 }}>
                Selected: {checkedSites.length}
                <Popconfirm
                  title="Confirmation"
                  description={`Delete selected ${checkedSites.length} sites?`}
                  onConfirm={() => {
                    dispatch(deleteSites(checkedSites));
                    setCheckedSites([]);
                  }}
                >
                  <Button
                    loading={isDeletionInProgress}
                    style={{
                      background: "red",
                      color: "white",
                      border: 0,
                      marginLeft: 10,
                    }}
                    type="default"
                    onClick={() => null}
                  >
                    <DeleteOutlined /> Delete
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Confirmation"
                  description={`Build/Rebuild selected ${checkedSites.length} sites?`}
                  onConfirm={async () => {
                    dispatch(
                      updateManySites(
                        {
                          status: "initial",
                          isInProgress: false,
                          statusAutoProgressOnCreate: true,
                          isDeployed: false,
                          error: false,
                        },
                        checkedSites
                      )
                    );
                    setCheckedSites([]);
                  }}
                >
                  <Button
                    loading={isDeletionInProgress}
                    style={{
                      background: "green",
                      color: "white",
                      border: 0,
                      marginLeft: 10,
                    }}
                    type="default"
                    onClick={() => null}
                  >
                    <RocketOutlined /> Build/Rebuild
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Confirmation"
                  description={`Build/Rebuild with new content selected ${checkedSites.length} sites?`}
                  onConfirm={async () => {
                    const tagsResponse = await tagsApi.loadAllTags();
                    if (tagsResponse.status === "success") {
                      const tags = tagsResponse?.tags;
                      const obj = {} as { [param: string]: string };
                      tags.forEach((t) => {
                        if (
                          t.fieldInDb !== "companyLocalPhone" &&
                          t.fieldInDb !== "companyLocalName" &&
                          t.fieldInDb !== "companyLocalPhoneFormatted"
                        ) {
                          obj[t.fieldInDb] = "";
                        }
                      });
                      dispatch(
                        updateManySites(
                          {
                            status: "initial",
                            isInProgress: false,
                            error: false,
                            htmlBuildLocation: "",
                            isDeployed: false,
                            ...obj,
                          },
                          checkedSites
                        )
                      );
                      await siteAPI.deleteManySpecificPageValuesBySitesIds(
                        checkedSites
                      );
                    }

                    setCheckedSites([]);
                  }}
                >
                  <Button
                    loading={isDeletionInProgress}
                    style={{
                      background: "green",
                      color: "white",
                      border: 0,
                      marginLeft: 10,
                    }}
                    type="default"
                    onClick={() => null}
                  >
                    <RocketOutlined /> Rebuild with new content
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Confirmation"
                  description={`Delete images from ${checkedSites.length} sites?`}
                  onConfirm={async () => {
                    checkedSites.forEach((cs) => {
                      dispatch(deleteAllSiteImages(cs));
                    });
                    setCheckedSites([]);
                  }}
                >
                  <Button
                    loading={isImageDeletingInProgress}
                    style={{
                      background: "green",
                      color: "white",
                      border: 0,
                      marginLeft: 10,
                    }}
                    type="default"
                    onClick={() => null}
                  >
                    Delete all images
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Confirmation"
                  description={`Stop selected ${checkedSites.length} sites?`}
                  onConfirm={async () => {
                    dispatch(
                      updateManySites(
                        {
                          isInProgress: false,
                          error: true,
                        },
                        checkedSites
                      )
                    );
                    setCheckedSites([]);
                  }}
                >
                  <Button
                    loading={isUpdatingSiteInProgress}
                    style={{
                      background: "red",
                      color: "white",
                      border: 0,
                      marginLeft: 10,
                    }}
                    type="default"
                    onClick={() => null}
                  >
                    <PauseCircleOutlined /> Stop
                  </Button>
                </Popconfirm>
                {checkedSitesWithError.length > 0 && (
                  <Popconfirm
                    title="Confirmation"
                    description={`Disable error?`}
                    onConfirm={async () => {
                      dispatch(
                        updateManySites(
                          {
                            isInProgress: false,
                            error: false,
                          },
                          checkedSitesWithError
                        )
                      );
                      setCheckedSites([]);
                    }}
                  >
                    <Button
                      loading={isUpdatingSiteInProgress}
                      style={{
                        background: "red",
                        color: "white",
                        border: 0,
                        marginLeft: 10,
                      }}
                      type="default"
                      onClick={() => null}
                    >
                      Disable Error
                    </Button>
                  </Popconfirm>
                )}
              </div>
            )}
            {isGettingSitesInProgress && (
              <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
            )}
          </div>
          <Button style={{ width: 180 }} type="primary" onClick={showModal}>
            <PlusOutlined /> Create
          </Button>
        </div>

        {sites.length === 0 && isGettingSitesInProgress && <SitesPreloader />}
        <SitesList
          checkedSites={checkedSites}
          setCheckedSites={setCheckedSites}
        />
      </div>
      <Modal
        title="Create new sites"
        open={isModalOpen}
        footer={[]}
        onCancel={() => setIsModalOpen(false)}
      >
        <CreateNewSite setIsModalOpen={setIsModalOpen} />
      </Modal>
    </>
  );
};
