import { IDomain } from "../../types";
import { DomainActionsTypes } from "../actions/domainActions";

export const IS_DOMAINS_LOADING_IN_PROGRESS =
  "domain/IS_DOMAINS_LOADING_IN_PROGRESS";
export const SET_DOMAINS = "domain/SET_DOMAINS";
export const IS_DOMAINS_CREATING_IN_PROGRESS =
  "domain/IS_DOMAINS_CREATING_IN_PROGRESS";
export const IS_DOMAINS_UPDATING_IN_PROGRESS =
  "domain/IS_DOMAINS_UPDATING_IN_PROGRESS";
export const SET_IS_LOAD_STATISTIC_IN_PROGRESS =
  "domain/SET_IS_LOAD_STATISTIC_IN_PROGRESS";
export const SET_STATISTICS = "domain/SET_STATISTICS";

const initialState = {
  domains: [] as IDomain[],
  isDomainsLoadingInProgress: false,
  isDomainsCreatingInProgress: false,
  isDomainsUpdatingInProgress: false,
  isLoadStatisticInProgress: false,
  statistics: "",
};

type InitialStateType = typeof initialState;

export const domainReducer = (
  state = initialState,
  action: DomainActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_DOMAINS:
    case IS_DOMAINS_LOADING_IN_PROGRESS:
    case IS_DOMAINS_CREATING_IN_PROGRESS:
    case IS_DOMAINS_UPDATING_IN_PROGRESS:
    case SET_IS_LOAD_STATISTIC_IN_PROGRESS:
    case SET_STATISTICS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
