import { Button, Form, FormProps, Input, Select } from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { FieldType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../store";
import {
  addDomains,
  DomainThunkType,
  updDomain,
} from "../../store/actions/domainActions";
import { AnyAction } from "redux";

interface IProps {
  activeCategory: string;
}

export const DomainListTextArea = ({ activeCategory }: IProps) => {
  const [editedDomain, setEditedDomain] = useState("");
  const dispatch = useDispatch() as Dispatch<DomainThunkType | AnyAction>;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const isDomainsLoadingInProgress = useSelector(
    (state: AppStateType) => state.domain.isDomainsLoadingInProgress
  );
  const isDomainsCreatingInProgress = useSelector(
    (state: AppStateType) => state.domain.isDomainsCreatingInProgress
  );
  const isDomainsUpdatingInProgress = useSelector(
    (state: AppStateType) => state.domain.isDomainsUpdatingInProgress
  );
  const categories = useSelector(
    (state: AppStateType) => state.categories.categories
  );
  const domains = useSelector((state: AppStateType) => state.domain.domains);
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values.domains.split("\n"));
    const domains = values.domains.split("\n").map((d) => d.trim());

    dispatch(addDomains(domains, activeCategory));

    // dispatch(createAppliance({ ...values, category: activeCategory }));
    form.setFieldValue("domains", "");
  };

  const onFinish2: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    dispatch(
      updDomain(
        {
          domain: values.domain,
          category: values.category,
          zip: values.zip || "",
          companyName: values.companyName || "",
          isDisabled: values.isDisabled,
        },
        editedDomain,
        activeCategory,
        setEditedDomain
      )
    );
  };

  useEffect(() => {
    if (editedDomain) {
      form2.setFieldValue(
        "domain",
        domains.find((d) => d._id === editedDomain)?.domain
      );
      form2.setFieldValue(
        "zip",
        domains.find((d) => d._id === editedDomain)?.zip
      );
      form2.setFieldValue(
        "category",
        domains.find((d) => d._id === editedDomain)?.category
      );
      form2.setFieldValue(
        "companyName",
        domains.find((d) => d._id === editedDomain)?.companyName
      );
      form2.setFieldValue(
        "isDisabled",
        domains.find((d) => d._id === editedDomain)?.isDisabled
      );
    }
  }, [editedDomain]);

  return (
    <>
      <h3>Add domains</h3>
      <Form
        style={{ display: "flex", flexDirection: "column" }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item<FieldType> name="domains">
          <Input.TextArea
            placeholder="Domains list"
            style={{ width: "100%" }}
            size="middle"
          />
        </Form.Item>
        <Button
          htmlType="submit"
          loading={isDomainsCreatingInProgress}
          type="primary"
          size="middle"
          disabled={false}
        >
          Add
        </Button>
      </Form>
      <h3>Domains</h3>
      {isDomainsLoadingInProgress && <div>Loading domains...</div>}
      {domains.length === 0 && <div>no domains</div>}
      {[...domains]
        .sort((a, b) => {
          if (a.isDisabled === b.isDisabled) {
            return 0;
          }
          return a.isDisabled ? 1 : -1;
        })
        .map((d) => (
          <>
            {editedDomain !== d._id ? (
              <div
                style={{
                  opacity: d.isDisabled ? 0.5 : 1,
                  textDecoration: d.isDisabled ? "line-through" : "none",
                }}
              >
                <div>
                  {d.domain} {d.zip ? `/ ${d.zip}` : ""}{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                    onClick={() => (d._id ? setEditedDomain(d._id) : null)}
                  >
                    Edit
                  </span>
                </div>
                <div>{d.companyName}</div>
              </div>
            ) : (
              <Form
                onFinish={onFinish2}
                form={form2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Form.Item<FieldType> name="domain">
                    <Input
                      placeholder="domain"
                      style={{ width: 200 }}
                      size="middle"
                    />
                  </Form.Item>
                  <Form.Item<FieldType> name="zip">
                    <Input
                      placeholder="zip"
                      style={{ width: 100 }}
                      size="middle"
                    />
                  </Form.Item>
                </div>

                <Form.Item<FieldType> name="category">
                  <Select
                    placeholder="Select category"
                    optionFilterProp="children"
                    options={categories.map((c) => ({
                      value: c._id,
                      label: c.name,
                    }))}
                  />
                </Form.Item>
                <Form.Item<FieldType> name="companyName">
                  <Input
                    placeholder="Company name"
                    style={{ width: 300 }}
                    size="middle"
                  />
                </Form.Item>
                <Form.Item<FieldType> name="isDisabled" label="Is active">
                  <Select
                    placeholder="select"
                    optionFilterProp="children"
                    options={[
                      {
                        label: "disabled",
                        value: true,
                      },
                      {
                        label: "active",
                        value: false,
                      },
                    ]}
                  />
                </Form.Item>
                <Button
                  //style={{ marginLeft: 10 }}
                  htmlType="submit"
                  loading={isDomainsUpdatingInProgress}
                  type="primary"
                  size="middle"
                  disabled={isDomainsUpdatingInProgress}
                >
                  Save
                </Button>
                <Button
                  //style={{ marginLeft: 10 }}
                  onClick={() => setEditedDomain("")}
                  disabled={isDomainsUpdatingInProgress}
                  type="link"
                  size="middle"
                >
                  Cancel
                </Button>
              </Form>
            )}
            ----------------
          </>
        ))}
    </>
  );
};
