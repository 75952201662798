import { Button, Form, FormProps, Input, Select } from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { FieldType, ICategory } from "../../types";
import {
  ApplianceThunkType,
  createAppliance,
} from "../../store/actions/applianceActions";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../store";
import {
  createCategory,
  updateCategory,
} from "../../store/actions/categoriesActions";

interface IProps {
  activeCategory: string;
}

export const EditMainCategoryInput = ({ activeCategory }: IProps) => {
  const [category, setCategory] = useState<ICategory | null>(null);
  const dispatch = useDispatch() as Dispatch<ApplianceThunkType | AnyAction>;
  const [form] = Form.useForm();
  const isCreatingCategoryInProgress = useSelector(
    (state: AppStateType) => state.categories.isCreatingCategoryInProgress
  );
  const categories = useSelector(
    (state: AppStateType) => state.categories.categories
  );
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (values.name && values.type) {
      dispatch(updateCategory(values, activeCategory));
    }

    form.setFieldValue("name", "");
    form.setFieldValue("type", "");
  };

  useEffect(() => {
    let cat = categories?.find((cat) => cat._id === activeCategory);
    if (cat) {
      setCategory(cat);
    } else {
      setCategory(null);
    }
  }, [activeCategory, categories]);

  useEffect(() => {
    form.setFieldValue("name", category?.name);
    form.setFieldValue("type", category?.type);
    form.setFieldValue("schemaOrgType", category?.schemaOrgType);
  }, [category]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      //style={{ display: "flex", flexDirection: "column" }}
    >
      <Form.Item<FieldType> label="Category name" name="name">
        <Input placeholder="Category name" size="middle" />
      </Form.Item>
      <Form.Item<FieldType> label="Category type" name="type">
        <Select
          showSearch
          placeholder="Select type"
          optionFilterProp="children"
          options={[
            { value: "appliance", label: "appliance" },
            { value: "service", label: "service" },
          ]}
        />
      </Form.Item>
      <Form.Item<FieldType> label="Schema.org type" name="schemaOrgType">
        <Select
          showSearch
          placeholder="Select schema.org type"
          optionFilterProp="children"
          options={[
            { value: "AccountingService", label: "AccountingService" },
            { value: "ApartmentComplex", label: "ApartmentComplex" },
            { value: "AutoDealer", label: "AutoDealer" },
            { value: "AutoRepair", label: "AutoRepair" },
            { value: "AutomotiveBusiness", label: "AutomotiveBusiness" },
            { value: "CafeOrCoffeeShop", label: "CafeOrCoffeeShop" },
            { value: "CarRental", label: "CarRental" },
            { value: "ClothingStore", label: "ClothingStore" },
            { value: "CollegeOrUniversity", label: "CollegeOrUniversity" },
            {
              value: "EducationalOrganization",
              label: "EducationalOrganization",
            },
            { value: "ElectronicsStore", label: "ElectronicsStore" },
            { value: "EntertainmentBusiness", label: "EntertainmentBusiness" },
            { value: "GovernmentOffice", label: "GovernmentOffice" },
            { value: "HairSalon", label: "HairSalon" },
            {
              value: "HealthAndBeautyBusiness",
              label: "HealthAndBeautyBusiness",
            },
            { value: "Hotel", label: "Hotel" },
            { value: "LegalService", label: "LegalService" },
            { value: "LocalBusiness", label: "LocalBusiness" },
            { value: "LocalService", label: "LocalService" },
            { value: "LodgingBusiness", label: "LodgingBusiness" },
            { value: "LogisticsBusiness", label: "LogisticsBusiness" },
            { value: "MedicalOrganization", label: "MedicalOrganization" },
            { value: "Motel", label: "Motel" },
            { value: "NGO", label: "NGO" },
            { value: "Pharmacy", label: "Pharmacy" },
            { value: "Preschool", label: "Preschool" },
            { value: "ProfessionalService", label: "ProfessionalService" },
            { value: "RealEstateAgent", label: "RealEstateAgent" },
            { value: "Residence", label: "Residence" },
            { value: "Restaurant", label: "Restaurant" },
            { value: "School", label: "School" },
            { value: "SingleFamilyResidence", label: "SingleFamilyResidence" },
            { value: "Storage", label: "Storage" },
            { value: "Supermarket", label: "Supermarket" },
            { value: "TouristAttraction", label: "TouristAttraction" },
          ]}
        />
      </Form.Item>
      <Button
        htmlType="submit"
        loading={isCreatingCategoryInProgress}
        type="primary"
        size="middle"
        disabled={false}
      >
        Save
      </Button>
    </Form>
  );
};
