import React, { useEffect, useState } from "react";
import { Select } from "antd";
import type { SelectProps } from "antd";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { mainUrl } from "../../helpers/constants";

let currentValue: string;

const fetch = async (value: string, callback: Function) => {
  currentValue = value;

  if (value) {
    const instance = axios.create({
      baseURL: mainUrl,
      headers: { "Content-Type": "application/json" },
    });
    if (currentValue === value && value.length > 3) {
      const res = await instance.get<any>(`api/v1/city/sug?q=${value}`);
      if (res.data.status === "success") {
        const data = res.data.cities.map((item: any) => ({
          value: item[1],
          text: item[0],
        }));
        callback(data);
      }
    }
  } else {
    callback([]);
  }
};

const SearchInput: React.FC<{
  placeholder: string;
  //style: React.CSSProperties;
  currentSearchParams?: { [param: string]: string };
}> = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<SelectProps["options"]>([]);
  const [value, setValue] = useState<string[]>();

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };

  const handleChange = (newValue: string[]) => {
    setValue(newValue);
    if (newValue.length > 0) {
      setSearchParams({
        ...props.currentSearchParams,
        city: newValue.toString(),
      });
    } else {
      searchParams.delete("city");
      setSearchParams(searchParams);
    }
  };

  return (
    <Select
      style={{ minWidth: 120 }}
      size="small"
      showSearch
      mode="tags"
      value={value}
      placeholder={props.placeholder}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
};

interface IProps {
  currentSearchParams?: { [param: string]: string };
}

export const SearchCityInput = ({ currentSearchParams }: IProps) => (
  <SearchInput placeholder="City" currentSearchParams={currentSearchParams} />
);
