import React, { Dispatch, useEffect, useState } from "react";
import { Button, Input, Form, Select, FormProps, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../store";
import {
  getCitiesByCounty,
  getCountiesByState,
} from "../../store/actions/locationActions";
import { SiteThunkType, createSite } from "../../store/actions/siteActions";
import { AnyAction } from "redux";
import { FieldType, IFormattedSelectItem } from "../../types";
import { getCompanies } from "../../store/actions/companyActions";

interface IProps {
  setIsModalOpen: (param: boolean) => void;
}

export const CreateNewSite = ({ setIsModalOpen }: IProps) => {
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;
  const [form] = Form.useForm();

  const [selectedState, setSelectedState] = useState<null | string>(null);
  const [selectedCounty, setSelectedCounty] = useState<null | string>(null);
  const [selectedCompany, setSelectedCompany] = useState<null | string>(null);
  const [selectedCity, setSelectedCity] = useState<null | string>(null);

  const [formattedStates, setFormattedStates] = useState(
    [] as IFormattedSelectItem[]
  );
  const [formattedCounties, setFormattedCounties] = useState(
    [] as IFormattedSelectItem[]
  );
  const [formattedCities, setFormattedCities] = useState(
    [] as IFormattedSelectItem[]
  );
  const [formattedZips, setFormattedZips] = useState(
    [] as IFormattedSelectItem[]
  );
  const [formattedCompanies, setFormattedCompanies] = useState(
    [] as IFormattedSelectItem[]
  );
  const isGettingStatesInProgress = useSelector(
    (state: AppStateType) => state.location.isGettingStatesInProgress
  );
  const isGettingCountiesInProgress = useSelector(
    (state: AppStateType) => state.location.isGettingCountiesInProgress
  );
  const isGettingCityByStateInProgress = useSelector(
    (state: AppStateType) => state.location.isGettingCityByStateInProgress
  );
  const isCreatingSiteInProgress = useSelector(
    (state: AppStateType) => state.site.isCreatingSiteInProgress
  );
  const states = useSelector((state: AppStateType) => state.location.states);

  const counties = useSelector(
    (state: AppStateType) => state.location.counties
  );
  const cities = useSelector((state: AppStateType) => state.location.cities);
  const companies = useSelector(
    (state: AppStateType) => state.company.companies
  );
  const handleChangeState = (value: string) => {
    setSelectedState(value);
  };
  const handleChangeCounty = (value: string) => {
    setSelectedCounty(value);
  };
  const handleChangeCity = (value: string) => {
    setSelectedCity(value);
  };
  const handleChangeCompany = (value: string) => {
    setSelectedCompany(value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("values", values);

    const stateId = states.find((c) => c.Abbreviation === values.state)?._id;

    if (stateId && values.county && values.company) {
      dispatch(
        createSite(
          values.company,
          stateId,
          values.county,
          values.city || "",
          values.zip || "",
          form,
          setIsModalOpen
        )
      );
    } else {
      notification.error({
        message: "Error",
        description: "No countyId, company or state id",
      });
    }
  };

  useEffect(() => {
    dispatch(getCompanies());
    form.setFieldValue("statusAutoProgressOnCreate", "true");
  }, []);

  useEffect(() => {
    const arr = [] as IFormattedSelectItem[];
    states.forEach((s) => {
      arr.push({ value: s?.Abbreviation, label: s?.State });
    });
    setFormattedStates(arr);
  }, [states]);

  useEffect(() => {
    const arr = [] as IFormattedSelectItem[];
    counties.forEach((s) => {
      arr.push({ value: s._id, label: s.county });
    });
    setFormattedCounties(arr);
  }, [counties]);

  useEffect(() => {
    const arr = [] as IFormattedSelectItem[];
    companies.forEach((s) => {
      arr.push({ value: s._id, label: s.name });
    });
    setFormattedCompanies(arr);
  }, [companies]);

  useEffect(() => {
    if (selectedState) {
      dispatch(getCountiesByState(selectedState));
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCounty) {
      const countyFips = counties.find((c) => c._id === selectedCounty);
      console.log("selectedCounty", selectedCounty);
      if (countyFips?.county_fips) {
        dispatch(getCitiesByCounty(String(countyFips?.county_fips)));
      }
    }
  }, [selectedCounty]);

  useEffect(() => {
    const arr = [] as IFormattedSelectItem[];
    cities.forEach((s) => {
      arr.push({ value: s._id, label: s.city });
    });
    setFormattedCities(arr);
  }, [cities]);

  useEffect(() => {
    let arr = [] as IFormattedSelectItem[];
    if (selectedCity) {
      const city = cities.find((c) => c._id === selectedCity);
      if (city) {
        const zipsString = city.zips;
        if (zipsString) {
          arr = zipsString
            ?.split(" ")
            ?.map((zip) => ({ label: zip.trim(), value: zip.trim() }));
        }
      }
      setFormattedZips(arr);
    }
  }, [selectedCity]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            paddingTop: 10,
          }}
        >
          <Form.Item<FieldType>
            style={{ marginRight: 20 }}
            label="Company"
            name="company"
            rules={[{ required: true, message: "Please select state" }]}
          >
            <Select
              loading={isGettingStatesInProgress}
              showSearch
              style={{ width: 210 }}
              placeholder="Select company"
              onChange={handleChangeCompany}
              optionFilterProp="children"
              options={formattedCompanies}
              filterOption={filterOption}
              value={selectedCompany}
            />
          </Form.Item>

          <Form.Item<FieldType>
            style={{ marginRight: 20 }}
            label="State"
            name="state"
            rules={[{ required: true, message: "Please select state" }]}
          >
            <Select
              loading={isGettingStatesInProgress}
              showSearch
              style={{ width: 210 }}
              placeholder="Select state"
              onChange={handleChangeState}
              optionFilterProp="children"
              options={formattedStates}
              filterOption={filterOption}
              value={selectedState}
            />
          </Form.Item>

          <Form.Item<FieldType>
            style={{ marginRight: 20 }}
            label="County"
            name="county"
            rules={[{ required: true, message: "Please select county" }]}
          >
            <Select
              disabled={!selectedState || isGettingCountiesInProgress}
              loading={isGettingCountiesInProgress}
              showSearch
              style={{ width: 210 }}
              placeholder="Select county"
              onChange={handleChangeCounty}
              optionFilterProp="children"
              options={formattedCounties}
              filterOption={filterOption}
              value={selectedCounty}
            />
          </Form.Item>

          <Form.Item<FieldType> label="City" name="city">
            <Select
              disabled={!selectedCounty}
              loading={isGettingCityByStateInProgress}
              showSearch
              style={{ width: 210 }}
              placeholder="Select city"
              onChange={handleChangeCity}
              optionFilterProp="children"
              options={formattedCities}
              filterOption={filterOption}
            />
          </Form.Item>

          <Form.Item<FieldType> label="Zip" name="zip">
            <Select
              disabled={!selectedCity}
              loading={isGettingCityByStateInProgress}
              showSearch
              style={{ width: 210 }}
              placeholder="Select zip"
              optionFilterProp="children"
              options={formattedZips}
              filterOption={filterOption}
            />
          </Form.Item>
        </div>

        <Button
          style={{ marginTop: 10 }}
          htmlType="submit"
          loading={isCreatingSiteInProgress}
          type="primary"
          size="middle"
          disabled={
            isGettingCountiesInProgress ||
            !selectedState ||
            !selectedCounty ||
            !selectedCompany
          }
        >
          Create sites
        </Button>
      </Form>
    </div>
  );
};
