import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  IS_DOMAINS_LOADING_IN_PROGRESS,
  SET_DOMAINS,
  IS_DOMAINS_CREATING_IN_PROGRESS,
  IS_DOMAINS_UPDATING_IN_PROGRESS,
  SET_IS_LOAD_STATISTIC_IN_PROGRESS,
  SET_STATISTICS,
} from "../reducers/domainReducer";
import { domainApi } from "../../api";
import { notification } from "antd";
import { IDomain } from "../../types";
import { isValidDomain } from "../../helpers/helpers";

type SetIsDomainsLoadingInProgress = {
  type: typeof IS_DOMAINS_LOADING_IN_PROGRESS;
  payload: { isDomainsLoadingInProgress: boolean };
};

type SetDomains = {
  type: typeof SET_DOMAINS;
  payload: { domains: IDomain[] };
};

type SetIsDomainsCreatingInProgress = {
  type: typeof IS_DOMAINS_CREATING_IN_PROGRESS;
  payload: { isDomainsCreatingInProgress: boolean };
};

type SetIsDomainsUpdatingInProgress = {
  type: typeof IS_DOMAINS_UPDATING_IN_PROGRESS;
  payload: { isDomainsUpdatingInProgress: boolean };
};

type SetIsLoadStatisticInProgress = {
  type: typeof SET_IS_LOAD_STATISTIC_IN_PROGRESS;
  payload: { isLoadStatisticInProgress: boolean };
};

type SetStatistics = {
  type: typeof SET_STATISTICS;
  payload: { statistics: string };
};

export type DomainActionsTypes =
  | SetIsDomainsLoadingInProgress
  | SetDomains
  | SetIsDomainsCreatingInProgress
  | SetIsDomainsUpdatingInProgress
  | SetIsLoadStatisticInProgress
  | SetStatistics;

export const setIsDomainsLoadingInProgress = (
  isDomainsLoadingInProgress: boolean
): SetIsDomainsLoadingInProgress => ({
  type: IS_DOMAINS_LOADING_IN_PROGRESS,
  payload: { isDomainsLoadingInProgress },
});

export const setIsDomainsCreatingInProgress = (
  isDomainsCreatingInProgress: boolean
): SetIsDomainsCreatingInProgress => ({
  type: IS_DOMAINS_CREATING_IN_PROGRESS,
  payload: { isDomainsCreatingInProgress },
});

export const setIsDomainsUpdatingInProgress = (
  isDomainsUpdatingInProgress: boolean
): SetIsDomainsUpdatingInProgress => ({
  type: IS_DOMAINS_UPDATING_IN_PROGRESS,
  payload: { isDomainsUpdatingInProgress },
});

export const setIsLoadStatisticInProgress = (
  isLoadStatisticInProgress: boolean
): SetIsLoadStatisticInProgress => ({
  type: SET_IS_LOAD_STATISTIC_IN_PROGRESS,
  payload: { isLoadStatisticInProgress },
});

export const setDomains = (domains: IDomain[]): SetDomains => ({
  type: SET_DOMAINS,
  payload: { domains },
});

export const setStatistics = (statistics: string): SetStatistics => ({
  type: SET_STATISTICS,
  payload: { statistics },
});

export type DomainThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  DomainActionsTypes
>;

export const getDomainsByCategory =
  (category: string): DomainThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsDomainsLoadingInProgress(true));
      const response = await domainApi.getDomainsByCategory(category);
      if (response.status === "success") {
        dispatch(setDomains(response.domains));
        console.log("response", response);
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading domains",
      });
    } finally {
      dispatch(setIsDomainsLoadingInProgress(false));
    }
  };

export const addDomains =
  (domains: string[], categoryId: string): DomainThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsDomainsCreatingInProgress(true));
      await domains.reduce(async (previousPromise, domain) => {
        await previousPromise;
        let trimmedDomain = domain.trim();

        let zip = "";
        if (trimmedDomain.includes(",")) {
          zip = trimmedDomain.split(",")?.[1]?.trim() || "";
          trimmedDomain = trimmedDomain.split(",")[0];
        }
        if (isValidDomain(trimmedDomain)) {
          await domainApi.createDomain({
            domain: trimmedDomain,
            category: categoryId,
            zip: zip ? zip : undefined,
          });
        }
      }, Promise.resolve());
      dispatch(getDomainsByCategory(categoryId));
      notification.success({
        message: "Success",
        description: "Added",
      });
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error adding domains",
      });
    } finally {
      dispatch(setIsDomainsCreatingInProgress(false));
    }
  };

export const updDomain =
  (
    domain: { [param: string]: string },
    domainId: string,
    categoryId: string,
    setEditedDomain: (param: string) => void
  ): DomainThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsDomainsUpdatingInProgress(true));
      const response = await domainApi.updateDomain(domain, domainId);

      if (response.status === "success") {
        dispatch(getDomainsByCategory(categoryId));
        notification.success({
          message: "Success",
          description: "Updated",
        });
        setEditedDomain("");
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error updating domain",
      });
    } finally {
      dispatch(setIsDomainsUpdatingInProgress(false));
    }
  };

export const loadStatistic =
  (
    activeDomain: string,
    startDate: string,
    finishDate: string
  ): DomainThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoadStatisticInProgress(true));
      const data = await domainApi.getStatistics(
        activeDomain,
        startDate,
        finishDate
      );

      if (data.status !== "success" || !Array.isArray(data.data)) {
        throw new Error("Invalid input format");
      }

      let res = "No data";
      res = data?.data
        ?.map((item: any) => {
          const key = item.keys[0].replace(/,/g, ""); // Убираем запятые из ключей
          return `${key}, position: ${item.position}, clicks: ${item.clicks}`;
        })
        .join("\n");

      dispatch(setStatistics(res));
    } catch (e) {
      dispatch(setStatistics(""));
      notification.error({
        message: "Error",
        description: "Error getting statistic",
      });
    } finally {
      dispatch(setIsLoadStatisticInProgress(false));
    }
  };
