import {
  Input,
  Table,
  Typography,
  Pagination,
  Select,
  Form,
  Checkbox,
  CheckboxProps,
  Tag,
  Modal,
  Button,
  Space,
  DatePicker,
  DatePickerProps,
  Popconfirm,
} from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { IFormattedSelectItem, ISiteRow, SortByField } from "../../types";
import { useSearchParams } from "react-router-dom";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { AppStateType } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  SiteThunkType,
  setPage,
  setSearchByCity,
  setSearchByCompany,
  setSearchByCounty,
  setSearchByError,
  setSearchByInProgress,
  setSearchByName,
  setSearchByState,
  setSearchByStatus,
  setSearchByZip,
  setSerchByDomain,
  setSortBy,
  setSortByField,
  writeArticle,
} from "../../store/actions/siteActions";
import { AnyAction } from "redux";
import { getBeautifullDate, timeAgo } from "../../helpers/helpers";
import { SearchCityInput } from "./SearchCityInput";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { LoadingOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { getCompanies } from "../../store/actions/companyActions";
import { SearchCountyInput } from "./SearchCountyInput";
import { mainUrl } from "../../helpers/constants";
import {
  loadStatistic,
  setStatistics,
} from "../../store/actions/domainActions";

interface IProps {
  checkedSites: string[];
  setCheckedSites: (param: string[]) => void;
}

export const SitesList = ({ checkedSites, setCheckedSites }: IProps) => {
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;
  const [dataSource, setDataSource] = useState<ISiteRow[]>([]);
  const [allSitesIds, setAllSitesIds] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeDomain, setActiveDomain] = useState("");
  const [activeSiteId, setActiveSiteId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");

  const [columns, setColumns] = useState<any[]>([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentSearchParams, setCurrentSearchParams] = useState<{
    [param: string]: string;
  }>();
  const [formattedStates, setFormattedStates] = useState(
    [] as IFormattedSelectItem[]
  );
  const { Text, Link } = Typography;
  const clickSortByField = (param: SortByField) => {
    setSearchParams({
      ...currentSearchParams,
      sortByField: param,
      sortBy: "asc",
      page: "1",
    });
  };
  const sortBy = useSelector((state: AppStateType) => state.site.sortBy);
  const sortByField = useSelector(
    (state: AppStateType) => state.site.sortByField
  );
  const sites = useSelector((state: AppStateType) => state.site.sites);
  const states = useSelector((state: AppStateType) => state.location.states);
  const isLoadStatisticInProgress = useSelector(
    (state: AppStateType) => state.domain.isLoadStatisticInProgress
  );
  const statistics = useSelector(
    (state: AppStateType) => state.domain.statistics
  );
  const companies = useSelector(
    (state: AppStateType) => state.company.companies
  );
  const sitesTotal = useSelector(
    (state: AppStateType) => state.site.sitesTotal
  );
  const isWritingArticleInProgress = useSelector(
    (state: AppStateType) => state.site.isWritingArticleInProgress
  );

  // const inProgressId = useSelector(
  //   (state: AppStateType) => state.site.inProgressId
  // );

  const isGettingStatesInProgress = useSelector(
    (state: AppStateType) => state.location.isGettingStatesInProgress
  );
  const getArrowIcon = () => {
    return sortBy === "asc" ? <ArrowUpOutlined /> : <ArrowDownOutlined />;
  };
  const setSort = () => {
    if (currentSearchParams?.sortBy === "asc") {
      setSearchParams({ ...currentSearchParams, sortBy: "desc", page: "1" });
    } else {
      setSearchParams({ ...currentSearchParams, sortBy: "asc", page: "1" });
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    const arr = [] as IFormattedSelectItem[];
    states.forEach((s) => {
      arr.push({ value: s._id, label: s.State });
    });
    setFormattedStates(arr);
  }, [states]);

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  const getStatusType = (status: string) => {
    if (status === "with_domain") {
      return "blue";
    }
    if (status === "with_content") {
      return "green";
    }
    if (status === "with_html") {
      return "red";
    }
    return "gold";
  };

  const onChange = (
    e: CheckboxChangeEvent,
    siteId: string,
    checked: string[]
  ) => {
    if (!e.target.checked) {
      setCheckedSites([...checked.filter((cs) => cs !== siteId)]);
    } else {
      setCheckedSites([...checked, siteId]);
    }
  };

  const checkAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setCheckedSites(allSitesIds);
    } else {
      setCheckedSites([]);
    }
  };

  useEffect(() => {
    const ds = [] as ISiteRow[];
    const sitesIds = [] as string[];
    sites.forEach((s) => {
      sitesIds.push(s._id.toString());
      ds.push({
        key: s._id,
        check: (
          <Checkbox
            checked={checkedSites.includes(s._id)}
            onChange={(e) => onChange(e, s._id, checkedSites)}
          ></Checkbox>
        ),
        logo: (
          <div>
            {s.companyLogo ? (
              <img
                width={50}
                alt="company logo"
                src={`${mainUrl}${s?.companyLogo?.replace(
                  "/data/uploads/",
                  ""
                )}`}
              />
            ) : (
              ""
            )}
          </div>
        ),
        name: (
          <div>
            <div>
              <Link
                style={{ color: s.error ? "red" : undefined }}
                href={`/sites/${s._id}`}
              >
                {s.statusAutoProgressOnCreate && (
                  <>
                    <ThunderboltOutlined style={{ color: "green" }} />{" "}
                  </>
                )}
                {s.isInProgress && (
                  <>
                    <LoadingOutlined style={{ color: "red" }} />{" "}
                  </>
                )}
                {s.name}
              </Link>
            </div>
            <div>
              {s.companyLocalName}{" "}
              <InfoCircleOutlined
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveDomain(`https://${s.domain}`);
                  setActiveSiteId(String(s._id));
                  showModal();
                  dispatch(setStatistics(""));
                }}
              />
            </div>
          </div>
        ),
        state: s.state?.State,
        city: s.city.city,
        zip: s?.zip,
        county: s.county.county_full,
        company: (
          <Tag color={s.company.color || "#2db7f5"}>{s.company.name}</Tag>
        ),
        domain: s.domain ? (
          <a target="_blank" href={`https://${s.domain}`}>
            {s.domain}
          </a>
        ) : (
          ""
        ),
        created: (
          <>
            <div>{getBeautifullDate(s.createdAt)}</div>
            <div>~{timeAgo(s.createdAt)}</div>
          </>
        ),
        updated: (
          <>
            <div>{getBeautifullDate(s.updatedAt)}</div>
            <div>~{timeAgo(s.updatedAt)}</div>
          </>
        ),
        isError: s.error ? <Tag color="#f50">ERROR</Tag> : "",
        inProgress: `${s.isInProgress}`,
        //status: s.status,
        status: <Tag color={getStatusType(s.status)}>{s.status}</Tag>,
      });
    });
    setDataSource(ds);
    setAllSitesIds(sitesIds);
  }, [sites, checkedSites]);

  const handleChangeState = (value: string) => {
    if (value.length > 0) {
      setSearchParams({
        ...currentSearchParams,
        page: "1",
        state: value.toString(),
      });
    } else {
      searchParams.delete("state");
      setSearchParams(searchParams);
    }
  };

  const handleChangeCompany = (value: string) => {
    if (value.length > 0) {
      setSearchParams({
        ...currentSearchParams,
        page: "1",
        company: value.toString(),
      });
    } else {
      searchParams.delete("company");
      setSearchParams(searchParams);
    }
  };
  const handleChangeStatus = (value: string) => {
    if (value.length > 0) {
      setSearchParams({
        ...currentSearchParams,
        page: "1",
        status: value.toString(),
      });
    } else {
      searchParams.delete("status");
      setSearchParams(searchParams);
    }
  };

  const handleChangeIsError = (value: string) => {
    setSearchParams({
      ...currentSearchParams,
      page: "1",
      error: value.toString(),
    });
  };

  const handleChangeInProgress = (value: string) => {
    setSearchParams({
      ...currentSearchParams,
      page: "1",
      inProgress: value.toString(),
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (activeDomain && startDate && finishDate) {
      dispatch(loadStatistic(activeDomain, startDate, finishDate));
    }
  }, [activeDomain, startDate, finishDate]);

  useEffect(() => {
    setColumns([
      {
        title: () => (
          <div>
            <Checkbox
              indeterminate={
                checkedSites.length > 0 &&
                checkedSites.length !== allSitesIds.length
              }
              checked={
                allSitesIds.length > 0 &&
                checkedSites.length === allSitesIds.length
              }
              onChange={checkAll}
            ></Checkbox>
          </div>
        ),
        dataIndex: "check",
        key: "check",
      },
      {
        title: "Logo",
        dataIndex: "logo",
        key: "logo",
      },
      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>Site name</span>
            <Form
              name="form_sitename"
              fields={[
                {
                  name: ["sitename"],
                  value: currentSearchParams?.serchByName || "",
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="sitename">
                <Input
                  allowClear
                  style={{ width: 140 }}
                  onBlur={(e) => {
                    if (e.target.value) {
                      setSearchParams({
                        ...currentSearchParams,
                        page: "1",
                        serchByName: e.target.value,
                      });
                    } else {
                      searchParams.delete("serchByName");
                      setSearchParams(searchParams);
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      // Логика при очистке
                      searchParams.delete("serchByName");
                      setSearchParams(searchParams);
                    }
                  }}
                  onPressEnter={(e) => {
                    const target = e.target as HTMLInputElement; // Явное приведение к HTMLInputElement
                    target.blur(); // Вызываем onBlur вручную
                  }}
                  size="small"
                  placeholder="Search by name"
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "name",
        key: "name",
      },
      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>State</span>
            <Form
              name="form_states"
              fields={[
                {
                  name: ["states"],
                  value: currentSearchParams?.state?.split(",") || [],
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="states">
                <Select
                  mode="tags"
                  size="small"
                  loading={isGettingStatesInProgress}
                  showSearch
                  style={{ minWidth: 120 }}
                  placeholder="Filter by state"
                  onChange={handleChangeState}
                  optionFilterProp="children"
                  options={formattedStates}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "state",
        key: "state",
      },
      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>County</span>
            <SearchCountyInput currentSearchParams={currentSearchParams} />
          </div>
        ),
        dataIndex: "county",
        key: "county",
      },
      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>City</span>
            <SearchCityInput currentSearchParams={currentSearchParams} />
          </div>
        ),
        dataIndex: "city",
        key: "city",
      },
      {
        title: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>Zip</span>
            <Form
              name="form_zip"
              fields={[
                {
                  name: ["sitezip"],
                  value: currentSearchParams?.serchByZip || "",
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="sitezip">
                <Input
                  allowClear
                  style={{ width: 100 }}
                  onBlur={(e) => {
                    if (e.target.value) {
                      setSearchParams({
                        ...currentSearchParams,
                        page: "1",
                        serchByZip: e.target.value,
                      });
                    } else {
                      searchParams.delete("serchByZip");
                      setSearchParams(searchParams);
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      // Срабатывает, когда поле очищено
                      searchParams.delete("serchByZip");
                      setSearchParams(searchParams);
                    }
                  }}
                  onPressEnter={(e) => {
                    const target = e.target as HTMLInputElement; // Явное приведение к типу HTMLInputElement
                    target.blur(); // Триггерим onBlur вручную
                  }}
                  size="small"
                  placeholder="Zip"
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "zip",
        key: "zip",
      },
      {
        title: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>Domain</span>
            <Form
              name="form_domain"
              fields={[
                {
                  name: ["sitedomain"],
                  value: currentSearchParams?.serchByDomain || "",
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="sitedomain">
                <Input
                  allowClear
                  style={{ width: 140 }}
                  onBlur={(e) => {
                    if (e.target.value) {
                      setSearchParams({
                        ...currentSearchParams,
                        page: "1",
                        serchByDomain: e.target.value,
                      });
                    } else {
                      searchParams.delete("serchByDomain");
                      setSearchParams(searchParams);
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      // Логика при очистке
                      searchParams.delete("serchByDomain");
                      setSearchParams(searchParams);
                    }
                  }}
                  onPressEnter={(e) => {
                    const target = e.target as HTMLInputElement; // Явное приведение к HTMLInputElement
                    target.blur(); // Вызываем onBlur вручную
                  }}
                  size="small"
                  placeholder="Domain"
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "domain",
        key: "domain",
      },
      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>Company</span>
            <Form
              name="form_companies"
              fields={[
                {
                  name: ["companies"],
                  value: currentSearchParams?.company?.split(",") || [],
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="companies">
                <Select
                  mode="tags"
                  size="small"
                  loading={isGettingStatesInProgress}
                  showSearch
                  style={{ minWidth: 120 }}
                  placeholder="Company"
                  onChange={handleChangeCompany}
                  optionFilterProp="children"
                  options={[
                    ...companies.map((c) => ({ value: c._id, label: c.name })),
                  ]}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "company",
        key: "company",
      },

      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>Status</span>
            <Form
              name="form_statuses"
              fields={[
                {
                  name: ["statuses"],
                  value: currentSearchParams?.status?.split(",") || [],
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="statuses">
                <Select
                  mode="tags"
                  size="small"
                  loading={isGettingStatesInProgress}
                  showSearch
                  style={{ minWidth: 120 }}
                  placeholder="Status"
                  onChange={handleChangeStatus}
                  optionFilterProp="children"
                  options={[
                    { value: "initial", label: "initial" },
                    { value: "with_domain", label: "with_domain" },
                    { value: "with_content", label: "with_content" },
                    { value: "with_html", label: "with_html" },
                  ]}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "status",
        key: "status",
      },
      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>Error</span>
            <Form
              name="form_error"
              fields={[
                {
                  name: ["errors"],
                  value: currentSearchParams?.error || "",
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="errors">
                <Select
                  size="small"
                  showSearch
                  style={{ minWidth: 80 }}
                  placeholder="Error"
                  onChange={handleChangeIsError}
                  optionFilterProp="children"
                  options={[
                    { value: "true", label: "true" },
                    { value: "false", label: "false" },
                  ]}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "isError",
        key: "isError",
      },
      {
        title: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: 4 }}>In Progress</span>
            <Form
              name="form_in_progress"
              fields={[
                {
                  name: ["inProgress"],
                  value: currentSearchParams?.inProgress || "",
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="inProgress">
                <Select
                  size="small"
                  showSearch
                  style={{ minWidth: 80 }}
                  placeholder="In Progress"
                  onChange={handleChangeInProgress}
                  optionFilterProp="children"
                  options={[
                    { value: "true", label: "true" },
                    { value: "false", label: "false" },
                  ]}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "inProgress",
        key: "inProgress",
      },
      {
        title: (
          <Link
            onClick={() => {
              currentSearchParams?.sortByField !== "createdAt"
                ? clickSortByField("createdAt")
                : setSort();
            }}
          >
            {sortByField === "createdAt" && getArrowIcon()} Created
          </Link>
        ),
        dataIndex: "created",
        key: "created",
      },
      {
        title: (
          <Link
            onClick={() => {
              currentSearchParams?.sortByField !== "updatedAt"
                ? clickSortByField("updatedAt")
                : setSort();
            }}
          >
            {sortByField === "updatedAt" && getArrowIcon()} Updated
          </Link>
        ),
        dataIndex: "updated",
        key: "updated",
      },
    ]);
  }, [
    currentSearchParams,
    isGettingStatesInProgress,
    formattedStates,
    states,
    companies,
    currentSearchParams?.serchByZip,
    checkedSites,
    allSitesIds,
  ]);

  useEffect(() => {
    console.log("currentSearchParams", currentSearchParams);
    dispatch(
      setSortBy((currentSearchParams?.sortBy as "desc" | "asc") || "desc")
    );
    dispatch(
      setSortByField(
        (currentSearchParams?.sortByField as SortByField) || "createdAt"
      )
    );
    dispatch(
      setPage(currentSearchParams?.page ? Number(currentSearchParams?.page) : 1)
    );
    dispatch(setSearchByName(currentSearchParams?.serchByName || ""));

    dispatch(setSearchByState(currentSearchParams?.state || ""));
    dispatch(setSearchByCity(currentSearchParams?.city || ""));
    dispatch(setSearchByZip(currentSearchParams?.serchByZip || ""));
    dispatch(setSerchByDomain(currentSearchParams?.serchByDomain || ""));
    dispatch(setSearchByCompany(currentSearchParams?.company || ""));
    dispatch(setSearchByStatus(currentSearchParams?.status || ""));
    dispatch(setSearchByCounty(currentSearchParams?.county || ""));
    dispatch(setSearchByError(currentSearchParams?.error || ""));
    dispatch(setSearchByInProgress(currentSearchParams?.inProgress || ""));
  }, [currentSearchParams]);

  useEffect(() => {
    setCurrentSearchParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  const onPageChange = (current: number) => {
    setSearchParams({ ...currentSearchParams, page: `${current}` });
  };

  const onChangeDateStart: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
    setStartDate(String(dateString));
  };
  const onChangeDateFinish: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    console.log(date, dateString);
    setFinishDate(String(dateString));
  };

  return (
    <div>
      <div style={{ paddingBottom: 20, paddingTop: 20 }}>
        {sitesTotal > 0 && (
          <Pagination
            showTotal={(total) => `Total ${total} items`}
            pageSize={50}
            defaultCurrent={1}
            current={
              currentSearchParams?.page ? Number(currentSearchParams?.page) : 1
            }
            total={sitesTotal}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        )}
      </div>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      <div style={{ paddingTop: 20 }}>
        {sitesTotal > 0 && (
          <Pagination
            showTotal={(total) => `Total ${total} items`}
            pageSize={50}
            defaultCurrent={1}
            current={
              currentSearchParams?.page ? Number(currentSearchParams?.page) : 1
            }
            total={sitesTotal}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        )}
      </div>
      <Modal
        title={`${activeDomain}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <Button type="primary" onClick={handleCancel}>
            Close
          </Button>
        }
      >
        <Space direction="horizontal">
          <DatePicker onChange={onChangeDateStart} />
          <DatePicker onChange={onChangeDateFinish} />
        </Space>
        {isLoadStatisticInProgress ? (
          <p>Loading...</p>
        ) : (
          <div>
            {statistics?.split("\n").map((stat) => (
              <p>
                {stat}{" "}
                <Popconfirm
                  title={stat.split(",")?.[0]}
                  description={`Write article?`}
                  onConfirm={() =>
                    !isWritingArticleInProgress
                      ? dispatch(
                          writeArticle(activeSiteId, stat.split(",")?.[0])
                        )
                      : null
                  }
                >
                  <span
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "red",
                    }}
                  >
                    {isWritingArticleInProgress
                      ? "loading..."
                      : "Create article"}
                  </span>
                </Popconfirm>
              </p>
            ))}
          </div>
        )}
      </Modal>
    </div>
  );
};
